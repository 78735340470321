import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { GetFloatLen, SetFloatLen, ToFixed, ToggleBasicFloatLen } from '@mini-code/base-func/number';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "number"
    }}>{`Number`}</h1>
    <p>{`basic-helper 提供统一处理 js 的数字、浮点数的方式，默认长度为小数点后 4 位，主要作用于`}</p>
    <ul>
      <li parentName="ul">{`ToFixed`}</li>
      <li parentName="ul">{`MoneyFormat`}</li>
    </ul>
    <h2 {...{
      "id": "获取基准浮点位数"
    }}>{`获取基准浮点位数`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { GetFloatLen } from 'basic-helper/number';

GetFloatLen();
`}</code></pre>
    <h2 {...{
      "id": "设置基准浮点位数"
    }}>{`设置基准浮点位数`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { SetFloatLen } from 'basic-helper/number';

let len = 1;

SetFloatLen(len);
`}</code></pre>
    <h2 {...{
      "id": "数字剪切和浮点数开关"
    }}>{`数字剪切和浮点数开关`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ToFixed, ToggleBasicFloatLen } from 'basic-helper/number';

let res = 2 / 3;

ToFixed(res);
ToggleBasicFloatLen();
`}</code></pre>
    <Playground __position={0} __code={'() => {\n  const initNumb = 2 / 3\n  const [numb, setNumb] = useState(initNumb)\n  const [isDisplayFloat, setFloat] = useState(true)\n  return (\n    <div>\n      <div>数字: {numb}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setNumb(ToFixed(numb))\n        }}\n      >\n        使用 ToFixed 剪切\n      </span>\n      <span\n        className=\"btn red mr10\"\n        onClick={e => {\n          SetFloatLen(2)\n        }}\n      >\n        设置为两位小数\n      </span>\n      <span\n        className=\"btn green mr10\"\n        onClick={e => {\n          setNumb(initNumb)\n          setFloat(ToggleBasicFloatLen())\n        }}\n      >\n        浮点小数{isDisplayFloat ? \'开\' : \'关\'}\n      </span>\n      <span\n        className=\"btn gold mr10\"\n        onClick={e => {\n          setNumb(initNumb)\n          setFloat(4)\n        }}\n      >\n        重置\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      GetFloatLen,
      SetFloatLen,
      ToFixed,
      ToggleBasicFloatLen
    }} mdxType="Playground">
  {() => {
        const initNumb = 2 / 3;
        const [numb, setNumb] = useState(initNumb);
        const [isDisplayFloat, setFloat] = useState(true);
        return <div>
          <div>数字: {numb}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            setNumb(ToFixed(numb));
          }}>使用 ToFixed 剪切</span>
          <span className="btn red mr10" onClick={e => {
            SetFloatLen(2);
          }}>设置为两位小数</span>
          <span className="btn green mr10" onClick={e => {
            setNumb(initNumb);
            setFloat(ToggleBasicFloatLen());
          }}>浮点小数{isDisplayFloat ? '开' : '关'}</span>
          <span className="btn gold mr10" onClick={e => {
            setNumb(initNumb);
            setFloat(4);
          }}>重置</span>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      